<template>
  <!-- <div class="wrapper">
		<HomeHeader  style="z-index:10"></HomeHeader>
		<LXContent></LXContent>
  </div>-->
  <div class="gunayu">
    <swiper ref="mySwiper"
            class="swiper"
            :options="swiperOption"
            :style="GetWindowHeight">
      <swiper-slide>
        <div class="bgimg">
          <img class="headPicture"
               src="@/static/img/lianxiwomen/bigbackground.png"
               alt />
          <div style="z-index: 6;"
               :style="GetWindowHeight">
            <div class="contentBox contentBoxFlex">
              <div class="contentTextBox">
                <div class="textBox">
                  <div class="">
                    <img src="@/static/img/lianxiwomen/headlinesImg.png"
                         alt="" />
                  </div>
                  <div class="textTitle">
                    <span>是一个互联网传媒领域的资源融合开放社区。</span>
                  </div>
                  <div class="textContent">
                    <p>
                      目前已有注册用户10W+，实现了互联网传媒领域的多范围覆盖。致力于营造良好的业态环境，为互联网传媒从业者实现资源多元化衔接，以社会化媒体洞察为依据，衍生更多变现模式。同时兼具业务阔拓、缔约、变现全过程的管理功能，并一站式解决了从业者的履约、结算等核心痛点。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img class="rightContent"
             src="@/static/img/lianxiwomen/contentbg.png"
             alt="右侧内容图" />
      </swiper-slide>
      <swiper-slide>
        <div class="twoimgBox">
          <div style="z-index: 6;height:100%">
            <!-- <div class="contentBox" style="position: relative;"> -->
            <!-- <div class="twoContentBox" style="height:100%;width: 100%;"> -->
            <!-- <div class="twoLeftBox"> -->
            <div style="">
              <div class="twoLeftImgText"
                   style="left: 10%;min-width: 400px;top: 55%;transform: translateY(-55%);">
                <img style="margin-left: -6px;"
                     src="@/static/img/lianxiwomen/anchorBg.png"
                     alt="" />
                <div class="twoLeftText">
                  <p>
                    您可以在网红头条APP内与业内人士交流、学习，甚至进行求助和发布需求。<br />
                    在<span class="cus_text">“教学直播”</span>版块，更是每天都有老司机分享自己的运营心得，帮助主播找到方向，促进直播能力的加强和进步！主播也可以在网红头条APP内给自己申请平台推荐资源，欢迎在直播一线的众多达人，加入网红头条的大家庭，分享您的日常！
                  </p>
                </div>
              </div>
            </div>
            <!-- </div> -->
            <!-- </div> -->
            <!-- <div class="pagingImg">
              <img src="../../../@/static/img/lianxiwomen/5-2.png" alt="" />
            </div> -->
            <!-- </div> -->
          </div>
        </div>
        <img style="width: 34%;right: 10.5%;"
             class="twoRightBgImg"
             src="@/static/img/lianxiwomen/twoContent.png"
             alt="" />
      </swiper-slide>
      <swiper-slide>
        <div class="treeimgBox">
          <div style="z-index: 6;height:100%">
            <!-- <div class="contentBox" style="position: relative;"> -->
            <div class="twoContentBox"
                 style="height:100%;width: 100%;">
              <div class="twoLeftBox">
                <div class="twoLeftImgText"
                     style="top: 54%;transform: translateY(-54%);">
                  <img style="margin-left: -6px;"
                       src="@/static/img/lianxiwomen/astrology.png"
                       alt="" />
                  <div class="twoLeftText treetext">
                    <p>
                      如果您身边有帅哥、美女资源，可以使用网红头条APP中的
                      <span class="cus_text">“星探功能”</span>
                      ，只需简单的操作，将可能成为主播的人推荐到平台，就可以获得稳定持久的收益，更有机会获得额外的
                      <span style="font-weight: 600;">“推荐奖励金”</span>
                      呦！ 您只负责推荐，剩下的交给平台内的
                      <span style="font-weight: 600;">“王牌运营”</span>
                      ，一切就是这么省心！至于推荐给谁？看您心情喽~
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
          <img class="treeRightBgImg"
               src="@/static/img/lianxiwomen/content3-1.png"
               alt="" />
          <!-- <div class="pagingImg">
            <img src="../../../@/static/img/lianxiwomen/5-3.png" alt="" />
          </div> -->
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="fourImgBox">
          <div style="z-index: 6;height:100%">
            <!-- <div class="contentBox" style="position: relative;"> -->
            <div class="twoContentBox"
                 style="height:100%;width: 100%;">
              <div class="twoLeftBox">
                <div class="twoLeftImgText">
                  <img style="margin-left: -6px;"
                       src="@/static/img/lianxiwomen/zhiboyunying.png"
                       alt="" />
                  <div class="twoLeftText">
                    <p>
                      如果您是直播运营公司（或者独立经纪人），没有自己的直播公会后台，只能找其他公会合作挂靠（或联营），那么您可以使用网红头条APP中的
                      <span class="cus_text">“中能实验室”</span>
                      功能，一站式解决“从主播录入到数据分析、再到收益灵活结算”，网红头条APP为您保驾护航，
                      <span class="cus_text">保证收益安全，无任何结算风险！</span>
                      “中能实验室”前身是“MCNopen经纪人管理系统”，连续运营两年未发生过一次失信案例！
                      当然，我们对合作者是有要求的呦，那就是“绝对不能对主播进行虚假承诺”，只要您合规经营，这里一定是您最稳定的合作伙伴！
                      <!-- <span style="color:#509DED;font-weight: 600;">“王牌运营”</span> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="pagingImg">
              <img src="../../../@/static/img/lianxiwomen/5-4.png" alt="" />
            </div> -->
            <!-- </div> -->
          </div>
        </div>
        <img class="treeRightBgImg fourRightImg"
             src="@/static/img/lianxiwomen/fourCotent.png"
             alt="" />
      </swiper-slide>
      <swiper-slide>
        <div class="fiveImgBox">
          <div style="z-index: 6;height:100%">
            <!-- <div class="contentBox" style="position: relative;"> -->
            <div class="twoContentBox"
                 style="height:100%;width: 100%;">
              <div class="twoLeftBox">
                <div class="twoLeftImgText fiveLeftImgText">
                  <img style="margin-left: -6px;"
                       src="@/static/img/lianxiwomen/fiveWhite.png"
                       alt="" />
                  <div class="twoLeftText fiveLeftText">
                    <p>
                      如果您是小白，您可以在网红头条内结识圈内朋友，建立自己的人脉关系。更是可以和网红头条深入合作，开启孵化之路，迅速成长为行业内一颗新星！您可以与我们进行双向选择，制定个性化的扶持计划，并签订保证效果的商业协议，剩下的，交给我们！
                    </p>
                  </div>
                  <img style="margin-left: -7px;margin-top: 12%;"
                       src="@/static/img/lianxiwomen/fiveInteract.png"
                       alt="" />
                </div>
              </div>
            </div>

            <!-- </div> -->
          </div>
        </div>

        <div id="footer"
             class="footer">
          豫ICP备19039737号 中能魔力（河南）网络科技有限公司
        </div>
        <img class="fiveRightImg "
             src="@/static/img/lianxiwomen/fiveRightImg.png"
             alt="" />
      </swiper-slide>
    </swiper>
    <div class="pagingImg1">
      <img :src="require(`../../static/img/lianxiwomen/5-${swiperIndex+1}.png`)"
           alt="11"
           srcset />
      <!-- <img :src="paginationList[swiperIndex]" /> -->
    </div>
    <!-- 底部 -->
    <!-- <div class="foot footFlex">
      <div class="info">
        <p>
          <a href="http://www.beian.miit.gov.cn/">豫ICP备 19039737号</a
          >中能魔力（河南）网络科技有限公司
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
var myVue = {};
export default {
  name: "Lianxiwomen",
  components: {
    swiper,
    swiperSlide
  },
  beforeCreate () {
    myVue = this;
  },
  data () {
    return {

      GetWindowHeight: {
        height: ""
      },
      swiperIndex: 0,
      swiperOption: {
        direction: "vertical", //滚动方向
        // slidesPerView: 3,
        // spaceBetween: 29,
        // pagination: {
        //   el: ".swiper-pagination",
        //   type: "custom",
        //   clickable: true
        // },
        // 设定初始化时slide的索引
        initialSlide: 0,
        // 箭头配置
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev"
        // },
        // height: this.GetWindowHeight.height,
        // slideToClickedSlide: !0,
        mousewheel: true,
        on: {
          slideChangeTransitionEnd: function () {
            myVue.swiperIndex = this.activeIndex;
            console.log(this.activeIndex);
          }
        }
      }
    };
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper;
    }
  },
  created () {
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  mounted () {
    document.getElementsByTagName("body")[0].className = "Hidden";
    console.log("Current Swiper instance object", this.mySwiper);
    //   this.mySwiper.slideTo(3, 1000, false)
  },
  beforeDestroy () {
    document.getElementsByTagName("body")[0].className = "";
  },
  filters: {
    numFilter (num) {
      return num < 9 ? "0" + (num + 1) : num + 1;
    }
  },
  methods: {
    getHeight () {
      // 获取浏览器高度，减去顶部导航栏的值70（可动态获取）,再减去head-DIV高度值80
      this.GetWindowHeight.height = window.innerHeight + "px";
      // document.getElementsByClassName
      // let swiperHeight = window.innerHeight;
      // console.log(swiperHeight);

      // let width = window.innerWidth;
      // console.log(width);
    }
  }
};
</script>
<style lang="scss" scoped>
.bgimg {
  width: 100%;
}
.wrapper {
  width: 100%;
  height: 80px;
}
.cusImg {
  width: 184px;
  height: 43px;
}
.right {
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.35rem;
  color: #dbdbdb;
  display: flex;

  .item {
    position: relative;
    // float left
    padding: 0 0.25rem;
    color: rgba(51, 51, 51, 1);
    a {
      color: rgba(51, 51, 51, 0.5);
      cursor: pointer;
    }
  }
}
.router-link-active {
  .item {
    a {
      cursor: pointer;
      color: #333333;
      font-size: 0.35rem;
      font-weight: 500;
    }
  }
}
.headeBox {
  width: 1240px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.headPicture {
  width: 80%;
  position: absolute;
}
.rightContent {
  position: absolute;
  right: 0;
  top: 10%;
  // transform: translateY(-13%);
  width: 49%;
  // height: 70%;
}
.contentBox {
  width: 100%;
  height: 100%;
  // margin: 0 auto;
  position: relative;
  .contentTextBox {
    // margin-right : 129px;
    // width: 100%;
    // padding-right: 129px;
    position: absolute;
    left: 10%;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .textTitle {
      margin-top: 21px;
      position: relative;
    }
    .textTitle::before {
      content: "";
      position: absolute;
      width: 40px;
      height: 2px;
      z-index: 1000;
      bottom: -13px;
      background-color: #df2a29;
      border-radius: 2px;
    }
    .textBox {
      z-index: 10;
      font-size: 20px;
      color: #333333;
    }
    .textContent {
      margin-top: 32px;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      width: 490px;
    }
  }
}
.contentBoxFlex {
  display: flex;
  justify-content: space-between;
}
.foot {
  height: 80px;
  width: 100%;
  background-color: #191919;
  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  text-align: center;
  font-size: 12px;
  padding-top: 38px;
  overflow: hidden;
}
.footFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiperBox {
  width: 1240px;
  margin: 0 auto;
}
.swiperBox .swiper-container {
  height: 605px;
}
.index-public-swiper-page {
  padding: 0 10px;
  font-size: 18px;
  line-height: 28px;
  color: #6e6e6e;
}
i,
em,
var {
  font-style: normal;
}
.twoimgBox {
  width: 100%;
  background-image: url("/@/static/img/lianxiwomen/twoBigBg.png");
  background-size: 50%;
  height: 100%;
  background-repeat: no-repeat;
}
.twoRightBgImg {
  position: absolute;
  right: 0;
  width: 41%;
  bottom: 0;
}
.twoLeftBox {
  // width: 490px;
  height: 100%;
  position: relative;
}
.twoLeftImgText {
  position: absolute;
  top: 60%;
  transform: translateY(-60%);
  width: 490px;
  left: 10%;
  min-width: 400px;

  .treetext {
    width: 390px;
  }
}
.twoLeftText {
  font-size: 16px;
  line-height: 30px;
}
.treeimgBox {
  width: 100%;
  background-image: "/@/static/img/lianxiwomen/bgImg3.png";
  background-size: 90%;
  height: 100%;
  background-repeat: no-repeat;
  background-position-x: 90%;
}
.treeRightBgImg {
  position: absolute;
  right: 8%;
  width: 54%;
  bottom: 0;
}
.treeRightBgImg.fourRightImg {
  width: 39%;
}
.fourImgBox {
  width: 100%;
  background-image: url("/@/static/img/lianxiwomen/fourBgimg.png");
  background-size: 90%;
  height: 100%;
  background-repeat: no-repeat;
  // background-position-x: 90%;
}
.fiveImgBox {
  width: 100%;
  background-image: url("/@/static/img/lianxiwomen/fiveBgImg.png");
  background-size: 14%;
  height: 100%;
  background-repeat: no-repeat;
  background-position-y: 20%;
}
.fiveLeftImgText {
  top: 47%;
  transform: translateY(-47%);
}
.fiveLeftText {
  width: 383px;
}
.fiveRightImg {
  position: absolute;
  right: 0;
  width: 53%;
  top: 50%;
  transform: translateY(-50%);
}
.footer,
.footer a {
  color: #4a4a4a;
}

.footer {
  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  height: 80px;
  text-align: center;
  font-size: 11px;
  line-height: 80px;
  overflow: hidden;
  background-color: #f9f9f9;
}
.pagingImg1 {
  position: fixed;
  left: 10%;
  top: 84%;
  transform: translateY(-84%);
  z-index: 15;
}
.pagingImg {
  position: absolute;
  left: 10%;
  top: 85%;
  transform: translateY(-85%);
}
.cus_text {
  color: #509ded;
  font-weight: 600;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-container .swiper-wrapper .swiper-slide {
  display: block;
  width: 100%;
  height: 100%;
  // background-color: #010001;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.swiper-slide {
  position: relative;
  flex-shrink: 0;
}
.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.router-link-active > .item::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 3px;
  z-index: 1000;
  bottom: -10px;
  background-color: #df2a29;
  border-radius: 2px;
  left: 0;
  right: 0;
  margin: auto;
}
.item:hover .itemText:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.itemText:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 3px;
  z-index: 1000;
  bottom: -10px;
  background-color: #df2a29;
  border-radius: 2px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
</style>